<template lang="pug">
#loading-screen
  #kit-logo
    icon(data="@icon/kit-logo.svg" color="#3F3F3F #151515")

  svg(
    :height="radius * 2"
    :width="radius * 2"
  )
    circle(
      stroke="#A9957B"
      :stroke-dasharray="circumference + ' ' + circumference"
      :style="{ strokeDashoffset: strokeDashoffset }"
      :stroke-width="stroke"
      fill="#373737"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    )

    g.text
      text#percentage(x="50%" y="50%" text-anchor="middle")
        tspan(alignment-baseline="central") Loading
        tspan.dot(alignment-baseline="central") .
        tspan.dot(alignment-baseline="central") .
        tspan.dot(alignment-baseline="central") .
</template>

<script>
export default {
  props: {
    progress: { type: Number, required: true },
    progressGate: { type: Number, required: true },
  },

  data() {
    return {
      stroke: 8,
      radius: 100,
      normalizedRadius: null,
      circumference: null,
    }
  },

  computed: {
    strokeDashoffset,
  },

  watch: {},

  created() {
    this.normalizedRadius = this.radius - this.stroke * 2
    this.circumference = this.normalizedRadius * 2 * Math.PI
  },

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
function strokeDashoffset() {
  return this.circumference - (this.progress / this.progressGate) * this.circumference
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
