<template lang="pug">
  #video-gate
    LoadingScreen(:progress="mediaLoadedPercent" :progressGate="percentGated")
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    percentGated: {
      type: Number,
      required: false,
      default: 50,
    },
  },

  data() {
    return {}
  },

  computed: {
    ...mapState({
      mediaLoadedPercent: state => state.steps.mediaLoadedPercent,
    }),
  },

  watch: {
    mediaLoadedPercent: {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (newVal >= this.percentGated) {
          // setTimeout(() => {
          //   // Give the animation a second to complete beforemoving to the next screen
          //   this.$emit('complete')
          // }, 1000)
          this.$emit('complete')
        }
      },
    },
  },

  methods: {
    gotoNext,
  },

  components: {
    LoadingScreen: require('@/components/LoadingScreen').default,
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function gotoNext() {
  this.$emit('complete')
}
</script>
